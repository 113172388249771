import logo from './logo.svg';
import './App.css';
import HomePage from './page/home';
import Room from './page/room';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

function App() {
  return (
    <div className="App">
    <Router>
        <Routes>
          <Route path="/home" element={<HomePage />} />
          <Route path="/room" element={<Room />} />
          <Route path="*" element={<HomePage />} />
        </Routes>
    </Router>
    </div>
  );
}

export default App;
