import React from 'react';
import { Bell, Search, Plus, Home, User } from 'lucide-react';

const HomePage = () => {
  return (
    <div className="min-h-screen bg-[#1C1C1E] text-white font-sans p-6">
      <header className="flex justify-between items-center mb-6">
        <h1 className="text-2xl font-bold">Welcome</h1>
        <div className="flex space-x-4">
          {/* <Bell className="w-6 h-6 text-gray-400" /> */}
          <User className="w-6 h-6 text-gray-400" />
        </div>
      </header>

      <div className="bg-[#2C2C2E] rounded-xl p-4 mb-6">
        <a href='/room'>
          <div className="flex items-center text-[#4CD964] text-sm mb-2">
            <div className="w-2 h-2 bg-[#4CD964] rounded-full mr-2"></div>
            <span>before 5m</span>
            <span className="ml-auto text-gray-400">more</span>
          </div>
          <p className="font-semibold">Lullaby club please come in...</p>
          <p className="text-gray-400 text-sm">Lullaby Club - Asaf & Friends Sundays</p>
        </a>
      </div>

      <h2 className="text-xl font-bold mb-4">Discover</h2>
      <div className="flex space-x-2 mb-4 overflow-x-auto">
        <button className="bg-white text-black px-4 py-2 rounded-full text-sm font-semibold">ALL</button>
        <button className="bg-[#2C2C2E] px-4 py-2 rounded-full text-sm">Ton</button>
        <button className="bg-[#2C2C2E] px-4 py-2 rounded-full text-sm">Bitcoin</button>
      </div>

      <div className="space-y-4">
        {[
          { title: 'Please come in and talk', tags: ['#design', '#newbie', '#UIUX'], speakers: 7, attendees: '10K' },
          { title: "Alejandro's talk show", tags: ['#music', '#talking', '#friend'], speakers: 3, attendees: '10K' },
          { title: 'Please come in and talk', tags: ['#design', '#newbie', '#UIUX'], speakers: 7, attendees: '10K' },
          { title: "Alejandro's talk show", tags: ['#music', '#talking', '#friend'], speakers: 3, attendees: '10K' },
          { title: 'Please come in and talk', tags: ['#design', '#newbie', '#UIUX'], speakers: 7, attendees: '10K' },
          { title: "Alejandro's talk show", tags: ['#music', '#talking', '#friend'], speakers: 3, attendees: '10K' },
          { title: 'Please come in and talk', tags: ['#design', '#newbie', '#UIUX'], speakers: 7, attendees: '10K' },
          { title: "Alejandro's talk show", tags: ['#music', '#talking', '#friend'], speakers: 3, attendees: '10K' },
          { title: 'Please come in and talk', tags: ['#design', '#newbie', '#UIUX'], speakers: 7, attendees: '10K' },
          { title: "Alejandro's talk show", tags: ['#music', '#talking', '#friend'], speakers: 3, attendees: '10K' }
        ].map((room, index) => (
          <div key={index} className="bg-[#2C2C2E] rounded-xl p-4">
            <a href='/room'>
              <div className="flex justify-between items-start mb-2">
                <div>
                  <p className="text-[#4CD964] text-sm mb-1">lullaby club</p>
                  <h3 className="font-semibold">{room.title}</h3>
                  <p className="text-gray-400 text-sm">{room.tags.join(' ')}</p>
                </div>
                <div className="flex -space-x-2">
                  <img className="w-8 h-8 rounded-full border-2 border-[#2C2C2E]" src="/api/placeholder/32/32" alt="User" />
                  <img className="w-8 h-8 rounded-full border-2 border-[#2C2C2E]" src="/api/placeholder/32/32" alt="User" />
                  <div className="w-8 h-8 rounded-full bg-gray-700 flex items-center justify-center text-xs">+6K</div>
                </div>
              </div>
              <div className="flex text-sm text-gray-400">
                <span>{room.speakers} Speakers</span>
                <span className="mx-2">•</span>
                <span>{room.attendees} Attendance</span>
              </div>
            </a>
          </div>
        ))}
      </div>

      <footer className="fixed bottom-2 left-1/2 transform -translate-x-1/2 bg-[#2C2C2E] py-2 px-6 flex justify-between items-center rounded-full w-11/12 max-w-md">
        <Home className="w-5 h-5 text-[#4CD964]" />
        <div className="w-5 h-5 text-gray-400" ></div>
        <Search className="w-5 h-5 text-gray-400" />
        <div className="w-5 h-5 text-gray-400" ></div>
        {/* <div className="relative"> */}
          <button className="absolute left-1/2 transform -translate-x-1/2 -translate-y-1/4 bg-[#4CD964] p-2 rounded-full shadow-lg">
            <svg xmlns="http://www.w3.org/2000/svg" className="w- h-5 text-white" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M12 4v16m8-8H4" />
            </svg>
          </button>
        {/* </div> */}
        <div className="w-5 h-5 text-gray-400" ></div>
        <User className="w-5 h-5 text-gray-400" />
        <div className="w-5 h-5 text-gray-400" ></div>
        <User className="w-5 h-5 text-gray-400" />
        {/* <img className="w-5 h-5 rounded-full" src="/api/placeholder/20/20" alt="User profile" /> */}
      </footer>

      
    </div>
  );
};

export default HomePage;