import React from 'react';
import { useNavigate } from 'react-router-dom';
import { X } from 'lucide-react';

const UserAvatar = ({ name }) => (
  <div className="flex flex-col items-center">
    <div className="w-16 h-16 bg-gray-300 rounded-full mb-2"></div>
    <span className="text-xs text-gray-300">{name}</span>
  </div>
);

const Room = () => {
  const navigate = useNavigate();

  const handleExit = () => {
    navigate('/home');
  };

  return (
    <div className="min-h-screen bg-[#1C1C1E] text-white font-sans p-6 relative">
      <button 
        onClick={handleExit}
        className="absolute top-4 left-4 text-gray-400 hover:text-white transition-colors"
        aria-label="나가기"
      >
        <X size={24} />
      </button>

      <h1 className="text-2xl font-bold mb-4 mt-12 text-center">Please come in and talk</h1>
      
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4">스피커</h2>
        <div className="grid grid-cols-2 gap-4">
          {['닉네임', '닉네임', '닉네임', '닉네임', '닉네임', '닉네임'].map((name, index) => (
            <UserAvatar key={index} name={name} />
          ))}
        </div>
      </div>
      
      <div>
        <h2 className="text-lg font-semibold mb-4">리스너</h2>
        <div className="grid grid-cols-4 gap-4">
          {Array(8).fill('닉네임').map((name, index) => (
            <UserAvatar key={index} name={name} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Room;